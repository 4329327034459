$size: 1em;
$speed: 2s;
$peakopacity: 0.9;
$arrow_color: rgba(255, 255, 255, $peakopacity);

.KmkOverlay {
  &-base {
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: +9000;
    &-container {
      height: 100%;
      width: 100%;
      &-bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 3 * $size;
        margin: auto;
      }
    }
    .clickable {
      pointer-events: all;
    }
    /*
      Scss to show floating pointing down arrow
    */
    .animatedDownArrows {
      position: relative;
      /**/
      position: absolute;
      bottom: 0 + $size;
      left: 50%;
      /**/
      width: $size;
      height: $size;
      transform: translate(-50%, -50%);

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: $size/3 solid rgba(0, 0, 0, $peakopacity);
        border-bottom: $size/3 solid rgba(0, 0, 0, $peakopacity);
        transform: translate($size/3, $size * 4/3) rotate(45deg);
        animation: animatedDownArrows $speed linear infinite;
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: $size/3 solid rgba(0, 0, 0, $peakopacity);
        border-bottom: $size/3 solid rgba(0, 0, 0, $peakopacity);
        transform: translate($size * 2/3, 0px) rotate(45deg);
        animation: animatedDownArrows $speed linear infinite $speed/-2;
      }
    }
    @keyframes animatedDownArrows {
      0% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/-6) rotate(45deg);
      }
      10%,
      90% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
      }
      50% {
        border-right: $size/3 solid $arrow_color;
        border-bottom: $size/3 solid $arrow_color;
        box-shadow: -10px -10px rotate(45deg);
        transform: translate($size/-6, 0px) rotate(45deg);
      }
      100% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/6) rotate(45deg);
      }
    }

    .animatedUpArrows {
      position: relative;
      /**/
      position: absolute;
      bottom: 0 + $size;
      left: 50%;
      /**/
      width: $size;
      height: $size;
      transform: translate(-50%, -50%);

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: $size/3 solid rgba(0, 0, 0, $peakopacity);
        border-bottom: $size/3 solid rgba(0, 0, 0, $peakopacity);
        transform: translate($size/3, $size * 4/3) rotate(225deg);
        animation: animatedUpArrows $speed linear infinite;
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: $size/3 solid rgba(0, 0, 0, $peakopacity);
        border-bottom: $size/3 solid rgba(0, 0, 0, $peakopacity);
        transform: translate($size * 2/3, 0px) rotate(225deg);
        animation: animatedUpArrows $speed linear infinite $speed/-2;
      }
    }
    @keyframes animatedUpArrows {
      0% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/-6) rotate(225deg);
      }
      10%,
      90% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
      }
      50% {
        border-right: $size/3 solid $arrow_color;
        border-bottom: $size/3 solid $arrow_color;
        box-shadow: -10px -10px rotate(225deg);
        transform: translate($size/-6, 0px) rotate(225deg);
      }
      100% {
        border-right: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/6) rotate(225deg);
      }
    }
  }
}
