.blog {
  &-root {
    &-container {
      display: flex;
      flex-direction: row;
      padding-top: 4rem;
    }
  }
  &-content {
    &-container {
      text-align: left;
      display: flex;
      @media (min-width: 768px) {
        padding: 0 2rem;
        flex: 3;
      }
    }
  }
  &-sidebar {
    &-container {
      text-align: left;
      display: flex;
      @media (min-width: 768px) {
        flex: 1;
      }
    }
  }
}
