body {
  height: 100%;
  // overflow: -moz-scrollbars-none; /*FF*/
  -ms-overflow-style: none; /*IE10+*/
  scrollbar-width: none;
}

.App {
  display: flex;
  text-align: center;
  background-color: black;
  // background-image: url("https://i.imgur.com/t4ELXvO.jpg");
  // background-image: url("/assets/img/kmklogo.png");
  // background-image: url("https://i.imgur.com/cLQjC5G.png");
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(223, 10, 10, 0.1)),
    url("https://i.imgur.com/cLQjC5G.png");
  // background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
  //   url("https://i.imgur.com/66F811E.jpg");
  // background-image: url("https://i.imgur.com/aPfe61L.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top right;
  // background-size: 20vw;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  min-height: 100vh;
}

.routerBase {
  &-container {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

// Scrollbar
::-webkit-scrollbar {
  display: none;
  width: 0.25em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(233, 141, 20);
}

// Fonts
@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-100.otf");
}

@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-200.otf");
  font-weight: 200;
}

@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-300.otf");
  font-weight: 300;
}

@font-face {
  font-family: Balamoa;
  src: url("/assets/fonts/Balamoa.ttf");
}

@font-face {
  font-family: CFHistoria;
  src: url("/assets/fonts/CFHistoria-Regular.ttf");
}
