.GlassOverlay {
  position: relative;
  min-height: 100%;
  min-width: 100%;

  overflow: hidden;

  border: #ffffff63 solid 2px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 3px 3px 2px #00000075;
  &-clone {
    position: absolute;
    top: 0;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    // width: 1000px;
    // height: 1000px;
    background: #fff;
    // background-image: url("/assets/img/srollbar_bg.png");
    // background-repeat: no-repeat;
    // background-attachment: fixed;
  }
}
