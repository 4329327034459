.remilia video {
  overflow: hidden;
  max-height: 80vmin;
}

.remilia {
  &-caption {
    background-color: white;
    font-size: 1em;
  }
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 10px solid;
  border-image: radial-gradient(rgb(251, 25, 5), rgb(98, 2, 2)) 1;
}
