body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.quizQuestion-container {
  background-color: white;
  text-align: left;
  padding: 0.25rem; }

.quizQuestions-container {
  width: 100vw;
  min-height: 100vh;
  background-color: blanchedalmond;
  padding: 4rem; }

.remilia video {
  overflow: hidden;
  max-height: 80vmin; }

.remilia {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 10px solid;
  -webkit-border-image: -webkit-radial-gradient(#fb1905, #620202) 1;
          border-image: radial-gradient(#fb1905, #620202) 1; }
  .remilia-caption {
    background-color: white;
    font-size: 1em; }

.transition-left {
  animation: pushLeft linear 0.75s;
  animation-iteration-count: infinite;
  transform-origin: 0% 50%;
  -webkit-animation: pushLeft linear 0.75s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 0% 50%;
  -moz-animation: pushLeft linear 0.75s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 0% 50%;
  -o-animation: pushLeft linear 0.75s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 0% 50%;
  -ms-animation: pushLeft linear 0.75s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 0% 50%; }

@keyframes pushLeft {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(-15px, 0px);
            transform: translate(-15px, 0px); } }

@-webkit-keyframes pushLeft {
  0% {
    -webkit-transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(-15px, 0px); } }

.transition-right {
  animation: pushRight linear 0.75s;
  animation-iteration-count: infinite;
  transform-origin: 0% 50%;
  -webkit-animation: pushRight linear 0.75s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 0% 50%;
  -moz-animation: pushRight linear 0.75s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 0% 50%;
  -o-animation: pushRight linear 0.75s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 0% 50%;
  -ms-animation: pushRight linear 0.75s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 0% 50%; }

@keyframes pushRight {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(15px, 0px);
            transform: translate(15px, 0px); } }

@-webkit-keyframes pushRight {
  0% {
    -webkit-transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(15px, 0px); } }

.kmkArrowRight {
  height: 1rem;
  width: 1rem;
  border-right: 0.33333rem solid white;
  border-top: 0.33333rem solid white;
  -webkit-transform: translate(0.33333rem, 1.33333rem) rotate(45deg);
          transform: translate(0.33333rem, 1.33333rem) rotate(45deg);
  -webkit-transform: translate(0.5rem, 0px) rotate(45deg);
          transform: translate(0.5rem, 0px) rotate(45deg); }

.kmkArrowLeft {
  height: 1rem;
  width: 1rem;
  border-left: 0.33333rem solid white;
  border-top: 0.33333rem solid white;
  -webkit-transform: translate(0.33333rem, 1.33333rem) rotate(45deg);
          transform: translate(0.33333rem, 1.33333rem) rotate(45deg);
  -webkit-transform: translate(-0.5rem, 0px) rotate(-45deg);
          transform: translate(-0.5rem, 0px) rotate(-45deg); }

body {
  height: 100%;
  -ms-overflow-style: none;
  /*IE10+*/
  scrollbar-width: none; }

.App {
  display: flex;
  text-align: center;
  background-color: black;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(223, 10, 10, 0.1)), url("https://i.imgur.com/cLQjC5G.png");
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(223, 10, 10, 0.1)), url("https://i.imgur.com/cLQjC5G.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top right;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  min-height: 100vh; }

.routerBase-container {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1; }

::-webkit-scrollbar {
  display: none;
  width: 0.25em; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e98d14; }

@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-100.otf"); }

@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-200.otf");
  font-weight: 200; }

@font-face {
  font-family: MavenProLight;
  src: url("/assets/fonts/maven-pro-light-300.otf");
  font-weight: 300; }

@font-face {
  font-family: Balamoa;
  src: url("/assets/fonts/Balamoa.ttf"); }

@font-face {
  font-family: CFHistoria;
  src: url("/assets/fonts/CFHistoria-Regular.ttf"); }

.blog-root-container {
  display: flex;
  flex-direction: row;
  padding-top: 4rem; }

.blog-content-container {
  text-align: left;
  display: flex; }
  @media (min-width: 768px) {
    .blog-content-container {
      padding: 0 2rem;
      flex: 3 1; } }

.blog-sidebar-container {
  text-align: left;
  display: flex; }
  @media (min-width: 768px) {
    .blog-sidebar-container {
      flex: 1 1; } }

.GlassOverlay {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  border: #ffffff63 solid 2px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 3px 3px 2px #00000075; }
  .GlassOverlay-clone {
    position: absolute;
    top: 0;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    background: #fff; }

.KmkOverlay-base {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: +9000;
  /*
      Scss to show floating pointing down arrow
    */ }
  .KmkOverlay-base-container {
    height: 100%;
    width: 100%; }
    .KmkOverlay-base-container-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 3em;
      margin: auto; }
  .KmkOverlay-base .clickable {
    pointer-events: all; }
  .KmkOverlay-base .animatedDownArrows {
    position: relative;
    /**/
    position: absolute;
    bottom: 1em;
    left: 50%;
    /**/
    width: 1em;
    height: 1em;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .KmkOverlay-base .animatedDownArrows:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-right: 0.33333em solid rgba(0, 0, 0, 0.9);
      border-bottom: 0.33333em solid rgba(0, 0, 0, 0.9);
      -webkit-transform: translate(0.33333em, 1.33333em) rotate(45deg);
              transform: translate(0.33333em, 1.33333em) rotate(45deg);
      -webkit-animation: animatedDownArrows 2s linear infinite;
              animation: animatedDownArrows 2s linear infinite; }
    .KmkOverlay-base .animatedDownArrows:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-right: 0.33333em solid rgba(0, 0, 0, 0.9);
      border-bottom: 0.33333em solid rgba(0, 0, 0, 0.9);
      -webkit-transform: translate(0.66667em, 0px) rotate(45deg);
              transform: translate(0.66667em, 0px) rotate(45deg);
      -webkit-animation: animatedDownArrows 2s linear infinite -1s;
              animation: animatedDownArrows 2s linear infinite -1s; }

@-webkit-keyframes animatedDownArrows {
  0% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, -0.66667em) rotate(45deg);
            transform: translate(-0.16667em, -0.66667em) rotate(45deg); }
  10%,
  90% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0); }
  50% {
    border-right: 0.33333em solid rgba(255, 255, 255, 0.9);
    border-bottom: 0.33333em solid rgba(255, 255, 255, 0.9);
    box-shadow: -10px -10px rotate(45deg);
    -webkit-transform: translate(-0.16667em, 0px) rotate(45deg);
            transform: translate(-0.16667em, 0px) rotate(45deg); }
  100% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, 0.66667em) rotate(45deg);
            transform: translate(-0.16667em, 0.66667em) rotate(45deg); } }

@keyframes animatedDownArrows {
  0% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, -0.66667em) rotate(45deg);
            transform: translate(-0.16667em, -0.66667em) rotate(45deg); }
  10%,
  90% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0); }
  50% {
    border-right: 0.33333em solid rgba(255, 255, 255, 0.9);
    border-bottom: 0.33333em solid rgba(255, 255, 255, 0.9);
    box-shadow: -10px -10px rotate(45deg);
    -webkit-transform: translate(-0.16667em, 0px) rotate(45deg);
            transform: translate(-0.16667em, 0px) rotate(45deg); }
  100% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, 0.66667em) rotate(45deg);
            transform: translate(-0.16667em, 0.66667em) rotate(45deg); } }
  .KmkOverlay-base .animatedUpArrows {
    position: relative;
    /**/
    position: absolute;
    bottom: 1em;
    left: 50%;
    /**/
    width: 1em;
    height: 1em;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .KmkOverlay-base .animatedUpArrows:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-right: 0.33333em solid rgba(0, 0, 0, 0.9);
      border-bottom: 0.33333em solid rgba(0, 0, 0, 0.9);
      -webkit-transform: translate(0.33333em, 1.33333em) rotate(225deg);
              transform: translate(0.33333em, 1.33333em) rotate(225deg);
      -webkit-animation: animatedUpArrows 2s linear infinite;
              animation: animatedUpArrows 2s linear infinite; }
    .KmkOverlay-base .animatedUpArrows:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-right: 0.33333em solid rgba(0, 0, 0, 0.9);
      border-bottom: 0.33333em solid rgba(0, 0, 0, 0.9);
      -webkit-transform: translate(0.66667em, 0px) rotate(225deg);
              transform: translate(0.66667em, 0px) rotate(225deg);
      -webkit-animation: animatedUpArrows 2s linear infinite -1s;
              animation: animatedUpArrows 2s linear infinite -1s; }

@-webkit-keyframes animatedUpArrows {
  0% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, -0.66667em) rotate(225deg);
            transform: translate(-0.16667em, -0.66667em) rotate(225deg); }
  10%,
  90% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0); }
  50% {
    border-right: 0.33333em solid rgba(255, 255, 255, 0.9);
    border-bottom: 0.33333em solid rgba(255, 255, 255, 0.9);
    box-shadow: -10px -10px rotate(225deg);
    -webkit-transform: translate(-0.16667em, 0px) rotate(225deg);
            transform: translate(-0.16667em, 0px) rotate(225deg); }
  100% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, 0.66667em) rotate(225deg);
            transform: translate(-0.16667em, 0.66667em) rotate(225deg); } }

@keyframes animatedUpArrows {
  0% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, -0.66667em) rotate(225deg);
            transform: translate(-0.16667em, -0.66667em) rotate(225deg); }
  10%,
  90% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0); }
  50% {
    border-right: 0.33333em solid rgba(255, 255, 255, 0.9);
    border-bottom: 0.33333em solid rgba(255, 255, 255, 0.9);
    box-shadow: -10px -10px rotate(225deg);
    -webkit-transform: translate(-0.16667em, 0px) rotate(225deg);
            transform: translate(-0.16667em, 0px) rotate(225deg); }
  100% {
    border-right: 0.33333em solid rgba(0, 0, 0, 0);
    border-bottom: 0.33333em solid rgba(0, 0, 0, 0);
    -webkit-transform: translate(-0.16667em, 0.66667em) rotate(225deg);
            transform: translate(-0.16667em, 0.66667em) rotate(225deg); } }

@media (max-width: 768px) {
  a.ui.card,
  div.ui.card {
    width: 100% !important; } }

.MainIntro {
  display: flex; }
  .MainIntro-text {
    text-align: right;
    color: white; }
    .MainIntro-text h4 {
      margin: 0;
      font-family: MavenProLight, sans-serif;
      font-size: 1.25rem;
      font-weight: 300;
      text-shadow: 2px 2px 2px #000000; }
      @media (max-width: 1200px) {
        .MainIntro-text h4 {
          font-size: calc(1.05rem + 0.26667vw) ; } }
    .MainIntro-text h1 {
      margin: 0;
      font-family: CFHistoria, Gabriola, Georgia;
      font-weight: 100;
      font-size: 4.5rem;
      text-shadow: 2px 2px 2px #000000; }
      @media (max-width: 1200px) {
        .MainIntro-text h1 {
          font-size: calc(1.7rem + 3.73333vw) ; } }
  .MainIntro-logo {
    position: relative;
    min-height: 50vh;
    width: 80vw;
    max-width: 800px; }
    .MainIntro-logo img {
      top: 20%;
      position: absolute; }
    .MainIntro-logo div {
      top: 40%;
      position: absolute;
      right: 0; }
    .MainIntro-logo .steam {
      position: absolute;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      background-color: #fff;
      left: 100px;
      z-index: 0;
      opacity: 0; }
    .MainIntro-logo #steam-one {
      -webkit-animation: steam-one 4s ease-out infinite;
      animation: steam-one 4s ease-out infinite; }
    .MainIntro-logo #steam-two {
      -webkit-animation: steam-two 4s ease-out 0.5s infinite;
      animation: steam-two 4s ease-out 0.5s infinite; }
    .MainIntro-logo #steam-three {
      -webkit-animation: steam-one 4s ease-out 1s infinite;
      animation: steam-one 4s ease-out 1s infinite; }
    .MainIntro-logo #steam-four {
      -webkit-animation: steam-two 4s ease-out 1.5s infinite;
      animation: steam-two 4s ease-out 1.5s infinite; }

@-webkit-keyframes steam-one {
  0% {
    -webkit-transform: translateY(0) translateX(0) scale(0.25);
            transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.3; }
  100% {
    -webkit-transform: translateY(-200px) translateX(-20px) scale(1);
            transform: translateY(-200px) translateX(-20px) scale(1);
    opacity: 0; } }

@keyframes steam-one {
  0% {
    -webkit-transform: translateY(0) translateX(0) scale(0.25);
            transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.3; }
  100% {
    -webkit-transform: translateY(-200px) translateX(-20px) scale(1);
            transform: translateY(-200px) translateX(-20px) scale(1);
    opacity: 0; } }

@-webkit-keyframes steam-two {
  0% {
    -webkit-transform: translateY(0) translateX(0) scale(0.25);
            transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.3; }
  100% {
    -webkit-transform: translateY(-200px) translateX(20px) scale(1);
            transform: translateY(-200px) translateX(20px) scale(1);
    opacity: 0; } }

@keyframes steam-two {
  0% {
    -webkit-transform: translateY(0) translateX(0) scale(0.25);
            transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.3; }
  100% {
    -webkit-transform: translateY(-200px) translateX(20px) scale(1);
            transform: translateY(-200px) translateX(20px) scale(1);
    opacity: 0; } }
    @media (max-width: 480px) {
      .MainIntro-logo img {
        width: 80vmin; }
      .MainIntro-logo .steam {
        height: 30vmin;
        width: 30vmin;
        left: 20vmin; }
      @-webkit-keyframes steam-one {
        0% {
          -webkit-transform: translateY(0) translateX(0) scale(0.25);
                  transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3; }
        100% {
          -webkit-transform: translateY(-30vmin) translateX(-20px) scale(1);
                  transform: translateY(-30vmin) translateX(-20px) scale(1);
          opacity: 0; } }
      @keyframes steam-one {
        0% {
          -webkit-transform: translateY(0) translateX(0) scale(0.25);
                  transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3; }
        100% {
          -webkit-transform: translateY(-30vmin) translateX(-20px) scale(1);
                  transform: translateY(-30vmin) translateX(-20px) scale(1);
          opacity: 0; } }
      @-webkit-keyframes steam-two {
        0% {
          -webkit-transform: translateY(0) translateX(0) scale(0.25);
                  transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3; }
        100% {
          -webkit-transform: translateY(-30vmin) translateX(20px) scale(1);
                  transform: translateY(-30vmin) translateX(20px) scale(1);
          opacity: 0; } }
      @keyframes steam-two {
        0% {
          -webkit-transform: translateY(0) translateX(0) scale(0.25);
                  transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3; }
        100% {
          -webkit-transform: translateY(-30vmin) translateX(20px) scale(1);
                  transform: translateY(-30vmin) translateX(20px) scale(1);
          opacity: 0; } } }

.TechGrid {
  text-align: center; }
  .TechGrid-container {
    position: relative; }
  .TechGrid img {
    margin-left: 1em;
    margin-right: 1em;
    height: 5em;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3)); }

.Welcome-container {
  display: flex;
  align-items: center;
  height: 100vh;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.Welcome-text {
  text-align: right;
  font-family: MavenProLight, sans-serif;
  color: white; }
  .Welcome-text-opaque {
    margin: 0;
    min-width: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.6); }
  .Welcome-text h4 {
    margin: 0;
    font-family: MavenProLight, sans-serif;
    font-size: 1.25rem;
    font-weight: 300;
    text-shadow: 2px 2px 2px #000000; }
    @media (max-width: 1200px) {
      .Welcome-text h4 {
        font-size: calc(1.05rem + 0.26667vw) ; } }
  .Welcome-text h1 {
    margin: 0;
    font-family: Balamoa;
    font-size: 7rem;
    text-shadow: 2px 2px 2px #000000; }
    @media (max-width: 1200px) {
      .Welcome-text h1 {
        font-size: calc(2.2rem + 6.4vw) ; } }
  .Welcome-text h2 {
    margin: 0;
    font-family: MavenProLight, sans-serif;
    font-size: 3em;
    text-shadow: 2px 2px 2px #000000; }

.remilia video {
  overflow: hidden;
  max-height: 80vmin; }

.remilia {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 10px solid;
  -webkit-border-image: -webkit-radial-gradient(#fb1905, #620202) 1;
          border-image: radial-gradient(#fb1905, #620202) 1; }
  .remilia-caption {
    background-color: white;
    font-size: 1em; }

.Contact-container {
  display: flex;
  flex-direction: row; }

.Contact-item {
  align-items: flex-end;
  text-align: center;
  align-content: center;
  vertical-align: middle;
  margin: 0.5rem;
  text-shadow: 0px 1px 3px #000000; }

@media (max-width: 768px) {
  .Contact-container {
    display: flex;
    flex-direction: column; }
  .Contact-item {
    align-items: flex-end;
    align-content: center;
    text-align: left;
    vertical-align: middle;
    margin: 0.5rem;
    text-shadow: 0px 1px 3px #000000; }
    .Contact-item p {
      display: inline-block; } }

.About-figure {
  margin: auto;
  color: white;
  text-align: center; }
  .About-figure figcaption {
    margin: 0.5rem; }
  .About-figure img {
    height: 15rem;
    border-radius: 50%; }
  .About-figure-container {
    text-align: left;
    margin: 1rem 0; }

.SkillBars-container {
  font-size: 1.1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (max-width: 1200px) {
    .SkillBars-container {
      font-size: calc(1.02rem + 0.10667vw) ; } }

.SkillBars-bar {
  margin-bottom: 0.5em; }

.SkillBars-bar:last-child {
  margin: 0; }

.SkillBars-labels {
  margin: 0;
  height: 100%;
  display: inline-block;
  width: 20%;
  align-content: flex-end;
  text-align: right;
  background-color: red;
  color: white;
  padding: 0.25em; }

.SkillBars-progress {
  padding: 0.25em;
  display: inline-block;
  position: relative;
  min-height: 100%;
  width: 79.8%;
  background-color: white;
  text-align: right; }
  .SkillBars-progress-fill {
    top: 0;
    left: 0;
    position: absolute;
    background-color: #990000;
    min-height: 100%; }

.KmkFullView {
  font-family: MavenProLight, sans-serif;
  font-weight: 300;
  display: flex;
  width: 100vw;
  font-size: 1.3rem; }
  @media (max-width: 1200px) {
    .KmkFullView {
      font-size: calc(1.06rem + 0.32vw) ; } }
  .KmkFullView p {
    color: white;
    text-shadow: 1px 1px 2px #000000;
    font-size: 1.3rem; }
    @media (max-width: 1200px) {
      .KmkFullView p {
        font-size: calc(1.06rem + 0.32vw) ; } }
  .KmkFullView .ui.header {
    color: #e79a1d;
    text-shadow: 1px 1px 1px #000000;
    font-size: 2rem; }
    @media (max-width: 1200px) {
      .KmkFullView .ui.header {
        font-size: calc(1.2rem + 1.06667vw) ; } }
  .KmkFullView .ui.header .sub.header {
    color: white;
    font-size: 1.4rem; }
    @media (max-width: 1200px) {
      .KmkFullView .ui.header .sub.header {
        font-size: calc(1.08rem + 0.42667vw) ; } }
  .KmkFullView-container {
    position: relative;
    min-height: 100vh; }
    .KmkFullView-container-center {
      margin: 5rem 5vmin;
      align-items: center; }
    .KmkFullView-container-background {
      position: absolute;
      min-height: 100%;
      background-size: cover;
      background-position: center; }
  .KmkFullView-content {
    margin: auto;
    text-align: left;
    z-index: +1; }

.kmkheader {
  z-index: +2; }
  .kmkheader-container {
    display: block;
    position: absolute;
    width: 100%;
    color: white; }
  .kmkheader-transparent {
    background-color: transparent; }
  .kmkheader-nav {
    display: flex;
    flex-grow: 1;
    justify-items: center; }
    .kmkheader-nav img {
      display: none;
      height: 4rem;
      margin: 1rem; }
    .kmkheader-nav ul {
      display: flex;
      list-style: none;
      flex-grow: 1;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      padding: 0; }
      @media (min-width: 768px) {
        .kmkheader-nav ul {
          display: flex;
          flex-grow: 0; }
          .kmkheader-nav ul li {
            font-size: 1rem; }
          .kmkheader-nav ul img {
            display: unset; } }
    .kmkheader-nav li {
      display: inline-block;
      margin: 3rem 1.25rem;
      font-size: 0.75rem;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none; }
    .kmkheader-nav li:hover {
      color: red;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); }
    .kmkheader-nav-fixed {
      position: fixed;
      overflow: hidden;
      width: 100%;
      background-image: -webkit-linear-gradient(black, rgba(0, 0, 0, 0));
      background-image: linear-gradient(black, rgba(0, 0, 0, 0));
      -webkit-transition: height 0.2s ease-in;
      transition: height 0.2s ease-in; }
      .kmkheader-nav-fixed ul img {
        height: 4rem;
        margin: 1rem; }
      .kmkheader-nav-fixed li {
        margin: 1.25rem; }
    .kmkheader-nav-fixed.expanded {
      padding-bottom: 1rem; }

