.About {
  &-figure {
    figcaption {
      margin: 0.5rem;
    }
    margin: auto;
    color: white;
    text-align: center;
    img {
      height: 15rem;
      border-radius: 50%;
    }
    &-container {
      text-align: left;
      margin: 1rem 0;
    }
  }
}

.SkillBars {
  &-container {
    @include rfs(1.1rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-bar {
    margin-bottom: 0.5em;
  }
  &-bar:last-child {
    margin: 0;
  }
  &-labels {
    margin: 0;
    height: 100%;
    display: inline-block;
    width: 20%;
    align-content: flex-end;
    text-align: right;

    background-color: red;
    color: white;
    padding: 0.25em;
  }
  &-progress {
    padding: 0.25em;
    display: inline-block;
    position: relative;
    min-height: 100%;
    width: 79.8%;
    background-color: white;
    text-align: right;
    &-fill {
      top: 0;
      left: 0;
      position: absolute;
      background-color: #990000;
      min-height: 100%;
    }
  }
}
