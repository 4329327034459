.Welcome {
  &-container {
    display: flex;
    align-items: center;
    height: 100vh;
    margin: auto;
    width: fit-content;
  }
  &-text {
    text-align: right;
    font-family: MavenProLight, sans-serif;
    color: white;
    &-opaque {
      margin: 0;
      min-width: 100%;
      min-height: 100vh;
      background-color: rgba(255, 255, 255, 0.6);
    }
    h4 {
      margin: 0;
      font-family: MavenProLight, sans-serif;
      @include rfs(1.25rem);
      font-weight: 300;
      text-shadow: 2px 2px 2px #000000;
    }
    h1 {
      margin: 0;
      font-family: Balamoa;
      @include rfs(7rem);
      text-shadow: 2px 2px 2px #000000;
    }
    h2 {
      margin: 0;
      font-family: MavenProLight, sans-serif;
      font-size: 3em;
      // font-weight: 100;
      text-shadow: 2px 2px 2px #000000;
    }
  }
}
