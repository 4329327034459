.Contact {
  &-container {
    display: flex;
    flex-direction: row;
  }
  &-item {
    align-items: flex-end;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    margin: 0.5rem;
    text-shadow: 0px 1px 3px #000000;
  }
  @media (max-width: $breakpoint-tablet) {
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-item {
      align-items: flex-end;
      align-content: center;
      text-align: left;
      vertical-align: middle;
      margin: 0.5rem;
      text-shadow: 0px 1px 3px #000000;
      p {
        display: inline-block;
      }
    }
  }
}
