.transition-left {
  animation: pushLeft linear 0.75s;
  animation-iteration-count: infinite;
  transform-origin: 0% 50%;
  -webkit-animation: pushLeft linear 0.75s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 0% 50%;
  -moz-animation: pushLeft linear 0.75s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 0% 50%;
  -o-animation: pushLeft linear 0.75s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 0% 50%;
  -ms-animation: pushLeft linear 0.75s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 0% 50%;
}

@keyframes pushLeft {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(-15px, 0px);
  }
}

@-moz-keyframes pushLeft {
  0% {
    -moz-transform: translate(0px, 0px);
  }
  100% {
    -moz-transform: translate(-15px, 0px);
  }
}

@-webkit-keyframes pushLeft {
  0% {
    -webkit-transform: translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(-15px, 0px);
  }
}

@-o-keyframes pushLeft {
  0% {
    -o-transform: translate(0px, 0px);
  }
  100% {
    -o-transform: translate(-15px, 0px);
  }
}

@-ms-keyframes pushLeft {
  0% {
    -ms-transform: translate(0px, 0px);
  }
  100% {
    -ms-transform: translate(-15px, 0px);
  }
}

.transition-right {
  animation: pushRight linear 0.75s;
  animation-iteration-count: infinite;
  transform-origin: 0% 50%;
  -webkit-animation: pushRight linear 0.75s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 0% 50%;
  -moz-animation: pushRight linear 0.75s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 0% 50%;
  -o-animation: pushRight linear 0.75s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 0% 50%;
  -ms-animation: pushRight linear 0.75s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 0% 50%;
}

@keyframes pushRight {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(15px, 0px);
  }
}

@-moz-keyframes pushRight {
  0% {
    -moz-transform: translate(0px, 0px);
  }
  100% {
    -moz-transform: translate(15px, 0px);
  }
}

@-webkit-keyframes pushRight {
  0% {
    -webkit-transform: translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(15px, 0px);
  }
}

@-o-keyframes pushRight {
  0% {
    -o-transform: translate(0px, 0px);
  }
  100% {
    -o-transform: translate(15px, 0px);
  }
}

@-ms-keyframes pushRight {
  0% {
    -ms-transform: translate(0px, 0px);
  }
  100% {
    -ms-transform: translate(15px, 0px);
  }
}
