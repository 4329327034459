@import "../../../node_modules/rfs/scss/rfs";
.MainIntro {
  display: flex;
  &-text {
    text-align: right;
    color: white;
    h4 {
      margin: 0;
      font-family: MavenProLight, sans-serif;
      @include rfs(1.25rem);
      font-weight: 300;
      text-shadow: 2px 2px 2px #000000;
    }
    h1 {
      margin: 0;
      font-family: CFHistoria, Gabriola, Georgia;
      font-weight: 100;
      @include rfs(4.5rem);
      text-shadow: 2px 2px 2px #000000;
    }
  }
  &-logo {
    position: relative;
    min-height: 50vh;
    width: 80vw;
    max-width: 800px;
    img {
      top: 20%;
      position: absolute;
    }
    div {
      top: 40%;
      position: absolute;
      right: 0;
    }
    // Animation
    .steam {
      position: absolute;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      background-color: #fff;
      left: 100px;
      z-index: 0;
      opacity: 0;
    }

    #steam-one {
      -webkit-animation: steam-one 4s ease-out infinite;
      animation: steam-one 4s ease-out infinite;
    }

    #steam-two {
      -webkit-animation: steam-two 4s ease-out 0.5s infinite;
      animation: steam-two 4s ease-out 0.5s infinite;
    }

    #steam-three {
      -webkit-animation: steam-one 4s ease-out 1s infinite;
      animation: steam-one 4s ease-out 1s infinite;
    }

    #steam-four {
      -webkit-animation: steam-two 4s ease-out 1.5s infinite;
      animation: steam-two 4s ease-out 1.5s infinite;
    }

    @-webkit-keyframes steam-one {
      0% {
        transform: translateY(0) translateX(0) scale(0.25);
        opacity: 0.3;
      }
      100% {
        transform: translateY(-200px) translateX(-20px) scale(1);
        opacity: 0;
      }
    }

    @keyframes steam-one {
      0% {
        transform: translateY(0) translateX(0) scale(0.25);
        opacity: 0.3;
      }
      100% {
        transform: translateY(-200px) translateX(-20px) scale(1);
        opacity: 0;
      }
    }

    @-webkit-keyframes steam-two {
      0% {
        transform: translateY(0) translateX(0) scale(0.25);
        opacity: 0.3;
      }
      100% {
        transform: translateY(-200px) translateX(20px) scale(1);
        opacity: 0;
      }
    }

    @keyframes steam-two {
      0% {
        transform: translateY(0) translateX(0) scale(0.25);
        opacity: 0.3;
      }
      100% {
        transform: translateY(-200px) translateX(20px) scale(1);
        opacity: 0;
      }
    }
    // Small screen
    @media (max-width: $breakpoint-mobile) {
      img {
        width: 80vmin;
      }
      .steam {
        height: 30vmin;
        width: 30vmin;
        left: 20vmin;
      }
      @-webkit-keyframes steam-one {
        0% {
          transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3;
        }
        100% {
          transform: translateY(-30vmin) translateX(-20px) scale(1);
          opacity: 0;
        }
      }

      @keyframes steam-one {
        0% {
          transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3;
        }
        100% {
          transform: translateY(-30vmin) translateX(-20px) scale(1);
          opacity: 0;
        }
      }

      @-webkit-keyframes steam-two {
        0% {
          transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3;
        }
        100% {
          transform: translateY(-30vmin) translateX(20px) scale(1);
          opacity: 0;
        }
      }

      @keyframes steam-two {
        0% {
          transform: translateY(0) translateX(0) scale(0.25);
          opacity: 0.3;
        }
        100% {
          transform: translateY(-30vmin) translateX(20px) scale(1);
          opacity: 0;
        }
      }
    }
  }
}
