.TechGrid {
  text-align: center;
  &-container {
    position: relative;
  }
  img {
    margin-left: 1em;
    margin-right: 1em;
    height: 5em;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  }
}
