.kmkheader {
  z-index: +2;
  &-container {
    display: block;
    position: absolute;
    width: 100%;
    color: white;
  }
  &-transparent {
    background-color: transparent;
  }
  &-nav {
    display: flex;
    flex-grow: 1;
    justify-items: center;
    img {
      display: none;
      height: 4rem;
      margin: 1rem;
    }
    ul {
      display: flex;
      list-style: none;
      flex-grow: 1;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      padding: 0;
      @media (min-width: $breakpoint-tablet) {
        display: flex;
        flex-grow: 0;
        li {
          font-size: 1rem;
        }
        img {
          display: unset;
        }
      }
    }
    li {
      display: inline-block;
      margin: 3rem 1.25rem;
      font-size: 0.75rem;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
    li:hover {
      color: red;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }

    &-fixed {
      // z-index: +2;
      position: fixed;
      overflow: hidden;
      width: 100%;
      // height: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      -moz-transition: height 0.2s ease-in;
      -o-transition: height 0.2s ease-in;
      -ms-transition: height 0.2s ease-in;
      transition: height 0.2s ease-in;
      ul {
        img {
          height: 4rem;
          margin: 1rem;
        }
      }
      li {
        margin: 1.25rem;
      }
    }
    &-fixed.expanded {
      // height: 4rem;
      padding-bottom: 1rem;
    }
  }
}
