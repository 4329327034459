.KmkFullView {
  font-family: MavenProLight, sans-serif;
  font-weight: 300;
  display: flex;
  width: 100vw;
  @include rfs(1.3rem);
  p {
    color: white;
    text-shadow: 1px 1px 2px #000000;
    @include rfs(1.3rem);
  }
  // Override semantic UIs
  .ui.header {
    color: rgb(231, 154, 29);
    text-shadow: 1px 1px 1px #000000;
    @include rfs(2rem);
  }
  // Override semantic UIs default
  .ui.header .sub.header {
    color: white;
    @include rfs(1.4rem);
  }
  &-container {
    position: relative;
    min-height: 100vh;
    &-center {
      margin: 5rem 5vmin;
      align-items: center;
    }
    &-background {
      position: absolute;
      min-height: 100%;
      background-size: cover;
      background-position: center;
    }
  }
  &-content {
    margin: auto;
    text-align: left;
    z-index: +1;
  }
}
